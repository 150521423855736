import React, { useEffect, useState } from "react";
import {
  BiPlay,
  BiChevronLeft,
  BiChevronRight,
  BiXCircle,
} from "react-icons/bi";

const ProductImageViewer = ({ product }) => {
  const [items, setItems] = useState([]);
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [open, setOpen] = useState(false);
  const [slideIndex, setSlideIndex] = useState(0);

  useEffect(() => {
    const images = product.gallery
      ? product.gallery.map((image) => ({
          altText: image.altText,
          src: image.localFile.publicURL,
        }))
      : [];

    const videos = product.videos
      ? product.videos.map((video) => {
          return {
            altText: video.videoThumbnail.altText,
            src: video.videoThumbnail.localFile.publicURL,
            url: video.url,
          };
        })
      : [];

    setImages(images);
    setVideos(videos);
    setItems([
      {
        altText: product.image.altText,
        src: product.image.localFile.publicURL,
      },
      ...images,
      ...videos,
    ]);
  }, [product]);

  const handleNextOrPrevious = (next) => {
    setSlideIndex((prevIndex) => {
      const itemCount = items.length;
      if (next) {
        return (prevIndex + 1) % itemCount;
      } else {
        return (prevIndex - 1 + itemCount) % itemCount;
      }
    });
  };

  const getVideoId = (url) => {
    var regExp =
      /^.*(youtu.be\/|v\/|e\/|u\/\w+\/|shorts|embed\/|v=)([^#\&\?]*).*/;
    var match = url.match(regExp);
    return match
      ? `https://www.youtube.com/embed/${match[match.length - 1]}`
      : "";
  };

  return (
    <>
      <div className="w-full lg:sticky top-4">
        <button
          type="button"
          onClick={() => {
            setSlideIndex(0);
            setOpen(true);
          }}
          className="block w-full mb-3"
        >
          <img
            src={product.image.localFile.publicURL}
            alt={product.image.altText}
            className="w-full rounded"
          />
        </button>
        <div className="grid grid-cols-5 gap-2">
          {images.map((image, index) => {
            if (index > 4) return null;

            if (index === 4)
              return (
                <button
                  className="bg-grey-lighter text-sm rounded text-grey-dark flex items-center justify-center"
                  type="button"
                  onClick={() => {
                    setSlideIndex(4);
                    setOpen(true);
                  }}
                >
                  +5
                </button>
              );

            return (
              <button
                key={index}
                type="button"
                className="relative block"
                onClick={() => {
                  setSlideIndex(index + 1);
                  setOpen(true);
                }}
              >
                <img
                  alt={image.altText}
                  className="object-cover rounded w-full aspect-square"
                  src={image.src}
                />
              </button>
            );
          })}
          {videos.map((video, index) => {
            if (index > 4) return null;

            if (index === 4)
              return (
                <button
                  className="bg-grey-lighter text-sm rounded text-grey-dark flex items-center justify-center"
                  type="button"
                  onClick={() => {
                    setSlideIndex(index + images.length + 1);
                    setOpen(true);
                  }}
                >
                  +5
                </button>
              );

            return (
              <button
                key={index}
                type="button"
                className="relative block"
                onClick={() => {
                  setSlideIndex(index + images.length + 1);
                  setOpen(true);
                }}
              >
                <img
                  alt={video.altText}
                  className="object-cover rounded w-full aspect-square"
                  src={video.src}
                />
                <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center text-white bg-black/30">
                  <BiPlay size={40} className="w-[50%]" />
                </div>
              </button>
            );
          })}
        </div>
      </div>
      {open && (
        <div className="h-screen w-full top-0 left-0 fixed z-50 bg-black/90 text-white">
          <div className="w-full h-full relative">
            {items.map((item, i) => {
              if (slideIndex !== i) return null;

              if (item.type === "video") {
                return (
                  <div
                    className={`absolute h-full w-full top-0 left-0 transition-opacity duration-500 ease-in-out flex items-center justify-center ${
                      slideIndex === i ? "opacity-100" : "opacity-0"
                    }`}
                    key={i}
                  >
                    <div className="w-[80%] mx-auto">
                      <div className="video-wrapper">
                        <iframe
                          width="560"
                          height="315"
                          src={getVideoId(item.url)}
                          title="YouTube video player"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          allowFullScreen
                          referrerPolicy="strict-origin-when-cross-origin"
                        ></iframe>
                      </div>
                    </div>
                  </div>
                );
              }

              return (
                <div
                  className={`absolute h-full w-full top-0 left-0 transition-opacity duration-500 ease-in-out flex items-center justify-center ${
                    slideIndex === i ? "opacity-100" : "opacity-0"
                  }`}
                  key={i}
                >
                  <img src={item.src} alt={item.altText} />
                </div>
              );
            })}
            <button
              className="absolute top-0 left-0 h-full flex items-center"
              type="button"
              onClick={() => handleNextOrPrevious(false)}
              aria-label="Previous"
            >
              <BiChevronLeft size={60} />
            </button>
            <button
              className="absolute top-0 right-0 h-full flex items-center"
              type="button"
              onClick={() => handleNextOrPrevious(true)}
              aria-label="Next"
            >
              <BiChevronRight size={60} />
            </button>
          </div>
          <button onClick={() => setOpen(false)} aria-label="close">
            <BiXCircle size={30} className="absolute right-6 top-6" />
          </button>
        </div>
      )}
    </>
  );
};
export default ProductImageViewer;
