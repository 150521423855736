import { useEffect, useState } from "react";
import { useStaticQuery, graphql } from "gatsby";

const useAllProducts = () => {
  const [allProducts, setAllProducts] = useState(null);

  const allProductsQuery = useStaticQuery(graphql`
    query allProductsQuery {
      allWpProduct {
        edges {
          node {
            title
            content
            date(formatString: "MMMM DD, YYYY")
            slug
            featuredImage {
              node {
                title
                altText
                localFile {
                  publicURL
                  childImageSharp {
                    gatsbyImageData(
                      width: 1200
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
            }
            product {
              mainH1
              description
              basePrice
              salePrice
              sku
              taxable
              category
              type
              cartCalloutText
              imageGallery {
                altText
                title
                localFile {
                  publicURL
                  childImageSharp {
                    gatsbyImageData(
                      width: 1200
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
              youtubeVideos {
                url
                videoThumbnail {
                  altText
                  localFile {
                    publicURL
                  }
                }
              }
              variations {
                variationName
                variationContent
                variationBasePrice
                variationSalePrice
                variationSeoTitle
                variationSeoDescription
                variationBase
                variationColor
                variationStyle
                variationSku
                variationSkus {
                  variationSkusSku
                }
                variationShortDescription
                variationDescription
                variationFeaturedImage {
                  title
                  localFile {
                    publicURL
                    childImageSharp {
                      gatsbyImageData(
                        width: 1200
                        placeholder: BLURRED
                        formats: [AUTO, WEBP, AVIF]
                      )
                    }
                  }
                }
                variationImageGallery {
                  altText
                  title
                  localFile {
                    publicURL
                    childImageSharp {
                      gatsbyImageData(
                        width: 1200
                        placeholder: BLURRED
                        formats: [AUTO, WEBP, AVIF]
                      )
                    }
                  }
                }
              }
              faqs {
                faqImage {
                  altText
                  localFile {
                    publicURL
                    childImageSharp {
                      gatsbyImageData(
                        width: 500
                        placeholder: BLURRED
                        formats: [AUTO, WEBP, AVIF]
                      )
                    }
                  }
                }
                faqQuestion
                faqAnswer
                faqGallery {
                  altText
                  title
                  caption
                  localFile {
                    publicURL
                    childImageSharp {
                      gatsbyImageData(
                        width: 1200
                        placeholder: BLURRED
                        formats: [AUTO, WEBP, AVIF]
                      )
                    }
                  }
                }
              }
            }

            seo {
              title
              metaDesc
              canonical
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    setAllProducts(allProductsQuery.allWpProduct.edges);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allProductsQuery]);

  return allProducts;
};
export default useAllProducts;
